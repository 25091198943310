import {get,post} from '../treasury';

//获取外部账户实体列表
export const counterList = (params) => get('/api/v1/counter', params)

//创建外部账⼾实体
export const counterCreate = (params) => post('/api/v1/counter', params)

// 外部账⼾添加钱包信息
export const counterDetails = (params) => get(`/api/v1/counter/${params}`)

// 外部账⼾添加钱包
export const counterAddressCreate = (params,params2) => post(`/api/v1/counter/${params}/address`,params2)

// 查询外部账⼾下的钱包地址列表
export const counteraddressList = (params,params2) => get(`/api/v1/counter/${params}/address`,params2)

