import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
// 防止路由重复点击报错
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
};
const routes = [{
    path: '/',
    redirect: '/login'
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import( /* webpackChunkName: "login" */ '../views/login.vue'),
  },
  {
    path: '/page',
    name: 'page',
    component: () => import( /* webpackChunkName: "login" */ '../views/page.vue'),
    children: [{
      path: '/dashboard',
      component: () => import('../views/dashboard/index')
    },{
      path: '/alldashboard',
      component: () => import('../views/dashboard/alldashboard')
    },{
      path: '/userManagement',
      component: () => import('../views/userManagement/index')
    },{
      path: '/wallets',
      component: () => import('../views/wallets/index')
    },{
      path: '/wallets/details',
      meta: {
        breadcrumb: 'Wallets/Wallets Details',
      },
      component: () => import('../views/wallets/details')
    },{
      path: '/userManagement/details',
      meta: {
        breadcrumb: 'User Management/User Details',
      },
      component: () => import('../views/userManagement/details')
    },{
      path: '/wallet',
      component: () => import('../views/Approval/wallet')
    },{
      path: '/wallet/details',
      meta: {
        breadcrumb: 'Approval-Wallet Management/Wallet Details',
      },
      component: () => import('../views/Approval/walletDetails')
    },{
      path: '/finance',
      component: () => import('../views/Approval/finance')
    },{
      path: '/finance/financeDetails',
      meta: {
        breadcrumb: 'Approval- Finance/Remittance Details',
      },
      component: () => import('../views/Approval/financeDetails')
    },{
      path: '/account',
      component: () => import('../views/Approval/account')
    },{
      path: '/account/accountDetails',
      meta: {
        breadcrumb: 'Approval - User Access/User Details',
      },
      component: () => import('../views/Approval/accountDetails')
    },{
      path: '/approvalCounterparty',
      component: () => import('../views/Approval/counterparty')
    },{
      path: '/approvalCounterparty/approvalCounterpartyDetails',
      meta: {
        breadcrumb: 'Approval - Counterparty / Detail Page',
      },
      component: () => import('../views/Approval/counterpartyDetails')
    },{
      path: '/policy',
      component: () => import('../views/Approval/policy')
    },{
      path: '/policy/policyDetails',
      meta: {
        breadcrumb: 'Approval - Policy / Detail Page',
      },
      component: () => import('../views/Approval/policyDetails')
    },{
      path: '/counterparty',
      component: () => import('../views/counterparty/index')
    },{
      path: '/counterparty/details',
      meta: {
        breadcrumb: 'Counter Party Register/Entity Details',
      },
      component: () => import('../views/counterparty/details')
    },{
      path: '/transfer',
      component: () => import('../views/transfer/index')
    },{
      path: '/transfer/internal',
      meta: {
        breadcrumb: 'Pay & Transfer/Internal Transfer',
      },
      component: () => import('../views/transfer/internal')
    },{
      path: '/transfer/transferDetails',
      meta: {
        breadcrumb: 'Pay & Transfer/Transfer Details',
      },
      component: () => import('../views/transfer/transferDetails')
    },
    {
      path: '/transfer/external',
      meta: {
        breadcrumb: 'Pay & Transfer/External Transfer',
      },
      component: () => import('../views/transfer/external')
    },{
      path: '/accessControl',
      component: () => import('../views/policy/index')
    },{
      path: '/pimary',
      component: () => import('../views/Investment/primary')
    },{
      path: '/holdings',
      component: () => import('../views/Investment/holdings')
    },{
      path: '/holdings/holdingsDetailes',
      meta: {
        breadcrumb: 'Dashboard-asset management / Token Details',
      },
      component: () => import('../views/Investment/holdingsDetailes')
    },{
      path: '/secondary',
      component: () => import('../views/Investment/secondary')
    },{
      path: '/financing',
      component: () => import('../views/financing/index')
    }]
  },
]

const router = new VueRouter({
  routes
})

export default router
