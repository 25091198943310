import * as wallets from "./wallets"
import * as approval from "./approval"
import * as counterparty from "./counterparty"
import * as user from "./user"
import * as login from "./login"
import * as policy from "./policy"


import {
  get,
 downloadFile
} from '../treasury';

//前后端系统字典接口获取
const download = (params) => downloadFile(`/api/v1/file/download`,params)

//获取当期用户的权限
const flowroles= (params) => get(`/api/v1/user/flow/roles`)
export {
  download,
  flowroles,
  wallets,
  approval,
  counterparty,
  user,
  login,
  policy
}
