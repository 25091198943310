import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI, {
  Table
} from 'element-ui';
import '../element-variables.scss';
import config from './utils/config.js'
import * as api from './utils/api/index.js'
import debounce from './utils/debounce.js'
// import conversionUSD from './utils/countUSD.js'

Vue.prototype.$api = api
Vue.use(config)
Vue.prototype.$debounce = debounce


Number.prototype.toFixed = function(d) {
  var s = this + "";
  if (!d) d = 0;
  if (s.indexOf(".") == -1) s += ".";
  s += new Array(d + 1).join("0");
  if (new RegExp("^(-|\\+)?(\\d+(\\.\\d{0," + (d + 1) + "})?)\\d*$").test(s)) {
    var s = "0" + RegExp.$2,
      pm = RegExp.$1,
      a = RegExp.$3.length,
      b = true;
    if (a == d + 2) {
      a = s.match(/\d/g);
      if (parseInt(a[a.length - 1]) > 4) {
        for (var i = a.length - 2; i >= 0; i--) {
          a[i] = parseInt(a[i]) + 1;
          if (a[i] == 10) {
            a[i] = 0;
            b = i != 1;
          } else break;
        }
      }
      s = a.join("").replace(new RegExp("(\\d+)(\\d{" + d + "})\\d$"), "$1.$2");

    }
    if (b) s = s.substr(1);
    return (pm + s).replace(/\.$/, "");
  }
  return this + "";

}

function toFixedFloor(num, decimal = 6) {
  if (num) {
    // let paddedNum = 1
    // let dividend = String(paddedNum).padEnd(decimal, '0');
    // console.log(num)
    // return (Math.floor(num * Math.pow(10, decimal)) / Math.pow(10, decimal)).toFixed(decimal)
    // return  Math.round(num * dividend) / dividend;
    // return Math.round(+num + 'e' + decimal) / Math.pow(10, decimal)
    const regexp = new RegExp(`^\\d+(?:\\.\\d{0,${decimal}})?`)
    const result = num.match(regexp)
    return result ? result[0] : ''
  }
  return 0
}
Vue.prototype.toFixedFloor = toFixedFloor
// Vue.prototype.conversionUSD = conversionUSD

import zhLocale from 'element-ui/lib/locale/lang/zh-CN'

Vue.config.productionTip = false
Vue.use(ElementUI, {
  size: 'small',
  locale: {
    el: {
      // 整体覆盖
      ...zhLocale.el,
      pagination: {
        pagesize: '/page',
        total: `Total {total} items`,
        goto: 'Go to',
        pageClassifier: ''
      },
    }
  }
});

Vue.directive('permission', {
  inserted(el, bind) {
    let flowList = JSON.parse(localStorage.getItem('flowList')) || []
    let data = bind.value[0]
    el.style = "display:none"
    if (flowList.length > 0) {
      if (flowList.some(item => data.includes(item))) {
        el.style = "display:true"
      }
    }
  }
})
Vue.prototype.permission = function(arr) {
  let mode = false
  let flowList = JSON.parse(localStorage.getItem('flowList')) || []
  if (flowList.length > 0) {
    if (flowList.some(item => arr.includes(item))) {
      mode = true
    }
  }
  return mode
}

/**计算列内容最大宽度
 * 遍历列的所有内容，获取最宽一列的宽度
 * @param arr
 */
Vue.prototype.getMaxLength = function(arr) {
  return arr.reduce((acc, item) => {
    if (item) {
      const calcLen = this.getTextWidth(item)
      if (acc < calcLen) {
        acc = calcLen
      }
    }
    return acc
  }, 0)
}
/**
 * 使用span标签包裹内容，然后计算span的宽度 width： px
 * @param valArr
 */
Vue.prototype.getTextWidth = function(str) {
  let width = 0
  const html = document.createElement('span')
  html.innerText = str
  html.className = 'getTextWidth'
  document.querySelector('body').appendChild(html)
  width = document.querySelector('.getTextWidth').offsetWidth
  document.querySelector('.getTextWidth').remove()
  return width
}
// 解决 ElTable 自动宽度高度导致的「ResizeObserver loop limit exceeded」问题
const fixElTableErr = (table) => {
  const oldResizeListener = table.methods.resizeListener;
  table.methods.resizeListener = function() {
    window.requestAnimationFrame(oldResizeListener.bind(this));
  };
};
// 一定要在Vue.use之前执行此函数
fixElTableErr(Table);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
