import {get,post} from '../treasury';

//获取审核列表
export const approvalList = (params) => get('/api/v1/approval', params)

//获取详情
export const approvalDetails = (params) => get(`/api/v1/approval/${params}`)

//提交Approval信息 测试用
export const approvalApproval = (params) => post(`/api/v1/approval/${params}`)

//用户审核
export const userexamine = (params) => post(`/api/v1/user/${params}/onboarding`)

//查询审核结果
export const resultcheck = (params) => get(`/api/v1/approval/result/check`,params)

//获取二维码
export const approvalSig = (params) => post(`/api/v1/approval/sig/init`, params)

//轮询
export const sigInfo = (params) => get('/api/v1/approval/sig/info', params)

// 刷新转账数据
export const finance = (params) => get(`/api/v1/approval/finance`,params)
