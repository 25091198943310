import {get,post} from '../treasury';
// import {get,post} from '../request';

//获取钱包列表
export const walletList = (params) => get('/api/v1/wallet', params)

//创建钱包请求
export const walletcreate = (params) => post('/api/v1/wallet/create', params)

//导⼊钱包请求接
export const walletimport = (params) => post('/api/v1/wallet/import',params)

//钱包地址真实余额查询
export const walletbalance = (params) => get(`/api/v1/wallet/${params}/balance`)

//获取钱包信息
export const walletDetails = (params) => get(`/api/v1/wallet/${params}`)

//钱包已授权列表
export const walletassess = (params,params2) => get(`/api/v1/wallet/${params}/access`,params2)

//钱包设置授权多个用户请求
export const setuserwallet = (params,params2) => post(`/api/v1/wallet/${params}/user/access`,params2)

//多个钱包设置一个用户权限的请求
export const setwalletuser = (params,params2) => post(`/api/v1/wallet/user/${params}/access`,params2)

// 钱包作废请求
export const walletretire = (params,params2) => post(`/api/v1/wallet/${params}/retire`)

// 生成钱包地址请求
export const walletgenerate = (params,params2) => post(`/api/v1/wallet/${params}/generate`)

// 计算钱包的Gas
export const walletgas = (params,params2) => get(`/api/v1/wallet/${params}/gas`,params2)

// 内部转账
export const transferinternal = (params) => post(`/api/v1/transfer/internal`,params)

// 外部转账
export const transferexternal = (params) => post(`/api/v1/transfer/external`,params)

// 配置
export const configparams = (params) => get(`/api/v1/config/params`)

// 批量获取钱包信息
export const batchquery = (params) => post(`/api/v1/wallet/batch/query`,params)

// 转账列表

export const transactions = (params) => get(`/api/v1/transfer/transactions`,params)

// 查询某个事务
export const transactionsDetails = (params) => get(`/api/v1/transfer/transactions/${params}`)

// 获取用户钱包列表
export const userWalletList = (params,params2) => get(`/api/v1/user/${params}/wallet`,params2)

// 获取usd
export const conversion = (params) => get(`/api/v1/coin/price`,params)
