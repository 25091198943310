import {get,post,det} from '../treasury';

//获取Policy列表
export const flowpolicyList = (params) => get('/api/v1/flow/policy', params)

//创建Policy列表
export const createpolicy = (params) => post('/api/v1/flow/policy', params)

//创建Policy列表
export const deleteflow = (params) => det(`/api/v1/flow/policy/${params}`, )
