const state = {
  flowType: [{
    name: 'DA',
    value: 'Dashboard Access'
  }, {
    name: 'FP',
    value: 'Financial Policy'
  }, {
    name: 'UA',
    value: 'User Access'
  }, {
    name: 'II',
    value: 'Inconsequential Investment'
  }, {
    name: 'CP',
    value: 'Consequential Payment'
  }, {
    name: 'WC',
    value: 'Wallet Creation'
  }, {
    name: 'WI',
    value: 'Wallet Import'
  }, {
    name: 'WR',
    value: 'Wallet Retirement'
  }, {
    name: 'IT',
    value: 'Internal Transfer'
  }, {
    name: 'ET',
    value: 'External Transfer'
  }],
  actionType: [{
    name: '101',
    value: 'Wallet Creation'
  }, {
    name: '102',
    value: 'Wallet Import'
  }, {
    name: '201',
    value: 'User Access'
  }, {
    name: '301',
    value: 'Wallet Creation'
  }, {
    name: '302',
    value: 'Wallet Import'
  }, {
    name: '304',
    value: 'Wallet Access'
  }, {
    name: '305',
    value: 'Wallet Access'
  }, {
    name: '401',
    value: 'Counter Party Creation'
  }, {
    name: '402',
    value: 'Counter Party Address'
  }, {
    name: '501',
    value: 'Internal Transfer'
  }, {
    name: '502',
    value: 'External Transfer'
  }],
  statusType: [{
    id: '10',
    value: 'Waiting for Approval'
  }, {
    id: '20',
    value: 'Completed wait on execution'
  }, {
    id: '30',
    value: 'Approval successful'
  }, {
    id: '31',
    value: 'Approval failed'
  }],
  transferStatusType:[{
    id:'00',
    value:'Waiting for Approval'
  },{
    id:'01',
    value:'Approval failed'
  },{
    id:'02',
    value:'Approval successful'
  },{
    id:'10',
    value:'Submitting transaction processing'
  },{
    id:'20',
    value:'Transaction processed successfully'
  },{
    id:'21',
    value:'Transaction processing failed'
  }],
  selectList: {
    accessType: [],
    assetType: [],
    categories: [],
    flow: [],
    flowRole: [],
    network: [],
    securityLevel: [],
    walletPurpose: []
  },
  flowType: ''
}
const mutations = {
  upSelectList(state, val) {
    state.selectList = val;
  },
  upFlowType(state, val) {
    state.flowType = val;
  },
};

export default {
  namespaced: true,
  state,
  mutations
}
