import {get,post} from '../request';


//新建用户账号
export const createUser = (params,params2) => post(`/api/v1/user?type=${params}`,params2)

//生成otp
export const userOtp = (params) => get(`/api/v1/security/otp?length=${params}`)

//验证唯一性 userId
export const checkUserid = (params) => get(`/api/v1/user/extra/checkExists?userId=${params}`)

//验证唯一性 emailAddress
export const checkEmailAddress = (params) => get(`/api/v1/user/extra/checkExists?emailAddress=${params}`)

//列表
export const userList = (params) => get(`/api/v1/user`,params)

//获取以太坊货币信息
export const conversion = (params) => get(`/api/v1/tool/currency/ethereum`)

//列表
export const userDetails = (params) => get(`/api/v1/user/${params}`)

//批量获取用户账号信息
export const batchQuery = (params) => post(`/api/v1/user/extra/batchQuery`,params)
